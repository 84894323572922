/* eslint-disable import/no-unresolved */
import { config, modulesList } from '@/include/config';
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  //RouteRecordRaw,
} from 'vue-router';

// .net core module 
import DCSbGen5 from '@/router/dotnet/DCSbGen5';

// php module 
import SbGen5 from '@/router/php/SbGen5';
import ModulesPromo from '@/router/php/ModulesPromo';
import Subscription from '@/router/php/Subscription';

// vuex
import store from '@/store/index';
import{ auth }from '@/lib/oltAuth';
import{ oltUserInfo }from '@/lib/oltUserInfo';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const oAuth = new auth();


const routes = []; 

routes.push(...DCSbGen5);
routes.push(...SbGen5);
routes.push(...Subscription);

if(modulesList != null){
  if(modulesList.promo != null && modulesList.promo == true){
    routes.push(...ModulesPromo);
  }
}

let oUserInfo = new oltUserInfo() ;
    
const router = createRouter({
  //history: createWebHashHistory(config.baseUrl),
  //history: createWebHashHistory(),
  //history: createWebHistory(config.baseUrl),
  //history: createWebHistory(),
  
  mode: 'history', // 去掉#，需要路由模式改为history  
  //development npm run serve
  //production npm run build
  //history: process.env.NODE_ENV === 'production' ? createWebHistory() : createWebHashHistory(config.baseUrl),
  history: createWebHistory() ,
  
  linkActiveClass: 'cur',
  linkExactActiveClass: 'cur',  
  routes,
});
   
router.beforeEach((to, from, next) => {
  //console.log('beforeEach');

  let token = oAuth.getToken(store);

  //console.log('token : ', token);
  //console.log('to : ', to);
  //console.log('from : ', from);

  // 強制登出 SBDW5-2624
  if(to.name == 'Logout'){
    next();
    return;
  }
  
  // Line 另開瀏覽器
  // if(navigator.userAgent.indexOf('Line') > -1) {
  //   Swal
  //     .fire({
  //       icon: 'warning',
  //       text: '為能完整使用口袋學校全功能，將使用預設瀏覽器開啟。',
  //       confirmButtonText: '確定',
  //       confirmButtonColor: '#EA5E5E',
  //       showConfirmButton: true
  //     })
  //     .then((result) => {
  //       if(to.query && Object.keys(to.query).length > 0){
  //         window.location = window.location + '&openExternalBrowser=1';
  //       }else{
  //         window.location = window.location + '?openExternalBrowser=1';
  //       }
  //     });
  //     return;
  // }

  // 檢查是否尚未取得 token，若無就強制前往初始化畫面
  // Fiend 202304/24 ADD is null
  if(token == '' || (to.name == '' || to.name == undefined || to.name == null) || (from.name == '' || from.name == undefined || from.name == null)){
    if(to.name == 'Initialize'){
      // 當前往初始畫面，就直接轉跳
      next();
      return;
    }
    
    //console.log('to.query : ',to.query);
    // 當有指定前往的畫面時，就將指定的畫面進行儲存
    let tempQuery = to.query;

    tempQuery.to_name = to.name;
    next({ path: '/Initialize', query: tempQuery });
    return;
    
  }

  let useRouterViewPermission = false;
  let checkRouterViewPermission = true;

  if(modulesList != null){
    if(modulesList.routerViewPermission != null && modulesList.routerViewPermission == true){
      useRouterViewPermission = true;
    }
  }
  //console.log('to : ', to);
  //console.log('from : ', from);
  //console.log('useRouterViewPermission : ', useRouterViewPermission);

  // 跳轉權限檢查
  if(useRouterViewPermission == true){
    let routerToViewName = to.name;

    //console.log('routerToViewName : ', routerToViewName);
    //console.log('store : ', store);
    //console.log('isRouterViewPermission : ', store.getters.isRouterViewPermission);
    
    if(store.getters.isRouterViewPermission && routerToViewName != 'Initialize'){
      // 當有權限清單，並且不是初始畫面，就進行權限檢查ㄍ
      checkRouterViewPermission = false; // 當有要檢查跳轉權限就要先改成 false
      checkRouterViewPermission = oUserInfo.checkRouterViewPermission(store, routerToViewName);
    }
  }

  //console.log('checkRouterViewPermission : ', checkRouterViewPermission);

  if(useRouterViewPermission == true && !checkRouterViewPermission){
    // 有開啟檢查轉跳權限，並且不合法
    // 因為 Router 本身不負責畫面顯示的部分，所以無法掛載顯示元件，只好改成存放在 vuex，在 APP 開一個固定的計時器進行檢查並顯示
    oUserInfo.setRouterErrorMessage(store, '權限不足，無法前往此畫面。');
  }
  else{

    next();
  }
  //跳頁前強制關閉遮罩
  $('.modal-backdrop').remove();
  //跳頁前強制關閉警告視窗
  Swal.clickConfirm();
  Swal.clickCancel();
  Swal.clickDeny();
});

router.isReady().then(() => {
  //console.log('is Ready!!!!');
});

export default router;
